module.exports = [{
      plugin: require('C:/Users/Ken/Documents/work/anomalist/adllc/metanomalies/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"metanomalies","short_name":"starter","start_url":"/","background_color":"#663399","theme_color":"#663399","display":"minimal-ui","icon":"src/images/gatsby-icon.png"},
    },{
      plugin: require('C:/Users/Ken/Documents/work/anomalist/adllc/metanomalies/node_modules/gatsby-remark-autolink-headers/gatsby-browser.js'),
      options: {"plugins":[],"className":"post-toc-anchor"},
    },{
      plugin: require('C:/Users/Ken/Documents/work/anomalist/adllc/metanomalies/node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":590},
    },{
      plugin: require('C:/Users/Ken/Documents/work/anomalist/adllc/metanomalies/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
